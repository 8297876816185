(function() {
    'use strict';

    angular
        .module('corePRUGAGreatApp')
        .controller('AppController', AppController);

    AppController.$inject = ['$scope', '$state', '$window', '$base64', 'Auth', 
                             'Principal', 'LoginService', 'GLOBAL_URL', 'Account', 
                             'CommonService', 'EVENT', 'AlertService', '$rootScope', '$stateParams', 'FileNetService', 'authExpiredInterceptor', '$cookies', '$uibModal'];

    function AppController($scope, $state, $window, $base64, Auth, 
    		Principal, LoginService, GLOBAL_URL, Account, CommonService, EVENT, AlertService, $rootScope, $stateParams, FileNetService, authExpiredInterceptor, $cookies, $uibModal) {
        var vm = this;
        vm.currentDate = new Date();
        vm.lasBusinessDate = null;
        vm.isAuthenticated = Principal.isAuthenticated;
        
        vm.login = login;
        vm.logout = logout;
        vm.changeOffice = changeOffice;
        vm.currentOffice = null;
        vm.$state = $state;
        
        vm.NBRO = $base64.encode("RO1");
        vm.NBIP = $base64.encode("IP1");
        vm.NBVL = $base64.encode("VL4");        
        vm.isCollapsed1 = true;
        vm.isCollapsed2= true;
        vm.isCollapsed3 = true;
        vm.isCollapsed4 = true;
        vm.isCollapsed5 = true;
        vm.isCollapsed6 = true;
        vm.isCollapsed7 = true;
        vm.isCollapsed8 = true;
        vm.isCollapsed9 = true;
        vm.isCollapsed10 = true;
        vm.isCollapsed11 = true;
        vm.isCollapsed12 = true;
        vm.getLASBusinessDate = getLASBusinessDate;
        vm.stateReload = stateReload;
        vm.stateReloadReceipt = stateReloadReceipt;
        
        vm.isNew = false;
        vm.isRoleAccounting = true;

		var currentDate = new Date();
		currentDate.setHours(0,0,0,0)
		var tDate = new Date("04/23/2024")

		if(tDate <= currentDate && document.getElementById("fileTemplate") != null)
		{
			document.getElementById("fileTemplate").style.display = "none";
		}
        
        function stateReload() {
        	$state.transitionTo($state.current, $stateParams, {
        	      reload: true, inherit: false, notify: true 
        	});
        };
        function stateReloadReceipt() {
        	$state.transitionTo($state.current, {}, {
        	      reload: true, inherit: false, notify: true 
        	});
        };
        
        function login() {
            LoginService.open();
        };

        function logout() {
            Auth.logout();
			CommonService.returnValue({masterurl:'trail-logout', identify: $cookies.get('X-PRUGAGreatApp-session-identify')}).$promise.then(function(data) {});
			$cookies.remove('X-PRUGAGreatApp-session-identify');
			$window.open(GLOBAL_URL + '#/login', '_self');
        };
        
        function changeOffice() {
        	if (vm.currentOffice) {
        		$scope.clearMessage();
	        	Account.post(vm.currentOffice, 
	        		function(result) {
	        		vm.account = result;
	                vm.isAuthenticated = Principal.isAuthenticated;
	                vm.currentOffice = result.currentOffice;
	                $scope.addAlert('Hệ thống đã được chuyển sang đại lý [' + result.currentOffice.officeCode + ' - ' + result.currentOffice.officeName + ']', 'success');
	                $scope.$broadcast(EVENT.changeOffice, vm.currentOffice);
	        	});
        	}
        };
        
        getAccount();
        getRoleAccounting();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if(Principal.isAuthenticated()) {
                	vm.init();
                }
                if (account) {
                	if (!account.currentOffice) {
                		$scope.addAlert('Thông tin tổng đại lý không tìm thấy!', 'warning');
                	}
                	vm.currentOffice = account.currentOffice;
                	getLASBusinessDate();
                	var to = $rootScope.toState;
                	if (to.name === 'login') {
						vm.isAuthenticated = false;
						vm.account = null;
						Principal.authenticate(null);
						// $window.open(GLOBAL_URL + 'ga/search', '_self');
                	}
                }
            });
        };
        
        function getLASBusinessDate() {
        	CommonService.returnValue({masterurl:'las-business-date', identify: $cookies.get('X-PRUGAGreatApp-session-identify')}).$promise.then(function(data) {
        		if(data.response == -1000) {
        			vm.showConfirm();
        			return;
        		}
        		vm.lasBusinessDate = data.response;
        	});
        };

		        
        vm.init = function() {
				CommonService.returnValue({
				masterurl : 'count-new-category',
				day : '15'
			}, function(result) {
				if (result.response > 0) {
					vm.isNew = true;
				}

			});
		}
        
        vm.downLoadAttachment = function(attachmentId) {
			CommonService.get({
				masterurl : 'get-archive-data',
				archiveDataId : attachmentId
			}, function(result) {
				 var byteCharacters = atob(result.content);
				  var byteNumbers = new Array(byteCharacters.length);
				  for (var i = 0; i < byteCharacters.length; i++) {
					    byteNumbers[i] = byteCharacters.charCodeAt(i);
				  }
				  var byteArray = new Uint8Array(byteNumbers);
				  var blob=new Blob([byteArray], {type: 'application/pdf;charset=utf-8'});
				  if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
						window.navigator.msSaveBlob(blob, result.dataName);
			       } else { // for other browsers  
					    var url = $window.URL || $window.webkitURL;
					    var aTag = document.createElement("a");
			    		aTag.style = "display: none";
			    		aTag.target = "_blank";
			    		aTag.href = url.createObjectURL(blob);
			    		aTag.download = result.dataName;
			    		aTag.click();
			       }
			}, function(error) {
				$scope.addAlert('Không tìm thấy file. Vui lòng thử lại','warning');
			});
		};
		
		vm.downLoadAttachmentFTP = function(txnId, attachmentId) {
			CommonService.get({
				masterurl : 'get-file-ftp',
				txnId: txnId,
				archiveDataId : attachmentId
			}, function(result) {
				 var byteCharacters = atob(result.dataContent);
				  var byteNumbers = new Array(byteCharacters.length);
				  for (var i = 0; i < byteCharacters.length; i++) {
					    byteNumbers[i] = byteCharacters.charCodeAt(i);
				  }
				  var byteArray = new Uint8Array(byteNumbers);
				  var blob=new Blob([byteArray], {type: 'application/pdf;charset=utf-8'});
				  if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
						window.navigator.msSaveBlob(blob, result.dataName);
			       } else { // for other browsers  
					    var url = $window.URL || $window.webkitURL;
					    var aTag = document.createElement("a");
			    		aTag.style = "display: none";
			    		aTag.target = "_blank";
			    		aTag.href = url.createObjectURL(blob);
			    		aTag.download = result.dataName;
			    		aTag.click();
			       }
			}, function(error) {
				$scope.addAlert('Không tìm thấy file. Vui lòng thử lại','warning');
			});
		};
        
		vm.downLoadTiffAttachmentFTP = function(txnId, attachmentId) {
			CommonService.get({
				masterurl : 'get-file-ftp',
				txnId: txnId,
				archiveDataId : attachmentId
			}, function(result) {
				 var byteCharacters = atob(result.dataContent);
				  var byteNumbers = new Array(byteCharacters.length);
				  for (var i = 0; i < byteCharacters.length; i++) {
					    byteNumbers[i] = byteCharacters.charCodeAt(i);
				  }
				  var byteArray = new Uint8Array(byteNumbers);
				  var blob=new Blob([byteArray], {type: 'image/tiff'});
				  if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
						window.navigator.msSaveBlob(blob, result.dataName);
			       } else { // for other browsers  
					    var url = $window.URL || $window.webkitURL;
					    var aTag = document.createElement("a");
			    		aTag.style = "display: none";
			    		aTag.target = "_blank";
			    		aTag.href = url.createObjectURL(blob);
			    		aTag.download = result.dataName;
			    		aTag.click();
			       }
			}, function(error) {
				$scope.addAlert('Không tìm thấy file. Vui lòng thử lại','warning');
			});
		};
		
        $rootScope.downloadFile = function(attach){
			// danh cho ie
			if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for
				// IE
				// browser
				if (attach.content != undefined) {
					var byteCharacters = atob(attach.content);
					var byteNumbers = new Array(byteCharacters.length);
					for (var i = 0; i < byteCharacters.length; i++) {
						byteNumbers[i] = byteCharacters.charCodeAt(i);
					}
					var byteArray = new Uint8Array(byteNumbers);
					var blob = new Blob([ byteArray ], {
						type : attach.dataType
					});
					window.navigator.msSaveBlob(blob, attach.fileName);
				} else {
					var blob = new Blob([ attach ], {
						type : attach.dataType
					});
					window.navigator.msSaveBlob(blob, attach.fileName);
				}
			} else {
				// truong hop file da luu duoi database
				if (attach.content != undefined) {
					var byteCharacters = atob(attach.content);
					var byteNumbers = new Array(byteCharacters.length);
					for (var i = 0; i < byteCharacters.length; i++) {
						byteNumbers[i] = byteCharacters.charCodeAt(i);
					}
					var byteArray = new Uint8Array(byteNumbers);
					var blob = new Blob([ byteArray ], {
						type : attach.dataType
					});
					var url = $window.URL || $window.webkitURL;
					var aTag = document.createElement("a");
					aTag.style = "display: none";
					aTag.href = url.createObjectURL(blob);
					aTag.download = attach.fileName;
					aTag.click();
				}
			}
		}
		function getRoleAccounting() {
			if ( vm.account) {
				for (var i = 0; i < vm.account.authorities.length; i++) {
	                if ( vm.account.authorities[i] === 'CS-GAGREAT-ACCOUTING') {
	                	vm.isRoleAccounting = false;
	                }
	            }
            }

        };

        vm.tiffDownload = function(txnId, attachmentId, path) {
			CommonService.get({
				masterurl : 'download-file-ftp',
				txnId: txnId,
				archiveDataId : attachmentId,
				path: path
			}, function(result) {
				if(result.dataContent != null) {
					var byteCharacters = atob(result.dataContent);
					  var byteNumbers = new Array(byteCharacters.length);
					  for (var i = 0; i < byteCharacters.length; i++) {
						    byteNumbers[i] = byteCharacters.charCodeAt(i);
					  }
					  var byteArray = new Uint8Array(byteNumbers);
					  var blob=new Blob([byteArray], {type: 'image/tiff'});
					  if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
							window.navigator.msSaveBlob(blob, result.dataName);
				       } else { // for other browsers  
						    var url = $window.URL || $window.webkitURL;
						    var aTag = document.createElement("a");
				    		aTag.style = "display: none";
				    		aTag.target = "_blank";
				    		aTag.href = url.createObjectURL(blob);
				    		aTag.download = result.dataName;
				    		aTag.click();
				       }
				}else {
					$scope.addAlert('Hệ thống lỗi. Vui lòng thử lại','warning');
				}
			}, function(error) {
				$scope.addAlert('Không tìm thấy file. Vui lòng thử lại','warning');
			});
		};
		
		vm.printReceiptFileNet = function(receiptNum){
    		self.loading = true;
    		FileNetService.get({method:'isExitsInFileNet', receiptNum:receiptNum}, function(result, headers){
    			self.loading = false;
    			if(headers('X-PRUGAGreatApp-error') !== 'filenet.not.found'){
    				$window.open(GLOBAL_URL + 'api/file-net/download?receiptNum='+ receiptNum + '&access_token=' + authExpiredInterceptor.getToken());    			    				
    			}
    		})
    	}
        
        vm.downloadFileFTP = function(txnId, attachmentId, path) {
			CommonService.get({
				masterurl : 'download-file-ftp',
				txnId: txnId,
				archiveDataId : attachmentId,
				path: path
			}, function(result) {
				if(result.dataContent != null) {
					var byteCharacters = atob(result.dataContent);
					  var byteNumbers = new Array(byteCharacters.length);
					  for (var i = 0; i < byteCharacters.length; i++) {
						    byteNumbers[i] = byteCharacters.charCodeAt(i);
					  }
					  var byteArray = new Uint8Array(byteNumbers);
					  var blob=new Blob([byteArray], {type: result.type});
					  if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
							window.navigator.msSaveBlob(blob, result.dataName);
				       } else { // for other browsers  
						    var url = $window.URL || $window.webkitURL;
						    var aTag = document.createElement("a");
				    		aTag.style = "display: none";
				    		aTag.target = "_blank";
				    		aTag.href = url.createObjectURL(blob);
				    		aTag.download = result.dataName;
				    		aTag.click();
				       }
				}else {
					$scope.addAlert('Hệ thống lỗi. Vui lòng thử lại','warning');
				}
			}, function(error) {
				$scope.addAlert('Không tìm thấy file. Vui lòng thử lại','warning');
			});
		};

		vm.showConfirm = function() {

			var modalInstance = $uibModal.open({
			      animation: true,
			      templateUrl: GLOBAL_URL
					+ 'partial?uri=session-expired-dialog',
			      controller: 'SessionDialogController as vm',
			      size: 'lg',
			      backdrop: 'static'
			    }).result.then(function () {
					 logout();
			    });
		  };
    }
})();
